import { useState } from "react";
import { useTrans } from "locales/hook";
import { useGTM } from "hooks/useGTM";

// ! Components
import { Button, Divider, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import FilterScore from "components/Filter/components/filterScore";
import { deepClone, removeFlatArray } from "helpers/helpers";
import ButtonFilter from "components/Filter/components/Button";

function DesktopFilter(props) {
    const {
        handleSubmit,
        fields,
        watch,
        dateEnd,
        remove,
        setValue,
        control,
        optionsDimension,
        reportId,
        append,
        getValues,
        dateStart,
        defaultFilter = {},
        reset,
        filterDefault,
        disableFilterField = [],
        reportNameSystem
    } = props;
    const t = useTrans();

    const getFilter = getValues('filter');
    const [isDisabledButton, setIsDisabledButton] = useState(false)
    const [isVisible, setIsVisible] = useState(false)
    const { attributes, detectClass } = useGTM({
        enabled: true,
        variables: {
            "ut-screen": "detail",
            "ut-name": 'apply_filter'
        }
    });

    const checkKeyDown = (e) => {
        if (e.key === 'Enter' && e.target.className.includes("chakra-input")) {
            e.preventDefault()
        } else if (e.key === 'Enter') {
            e.stopPropagation()
        }
    };

    const resignDimension = (selected: any) => {
        const cloneObject = deepClone(getFilter);

        const getCurrentDimension = cloneObject.map(y => y.dimension);
        const findIndexDimension = getCurrentDimension.findIndex(y => y === selected);

        getCurrentDimension.splice(findIndexDimension, 1);

        let result = removeFlatArray(getCurrentDimension, optionsDimension, "fieldName");

        result = result.filter(item => !disableFilterField.includes(item.fieldName));

        return result;
    };

    const emptyFilterState = () => {
        return <>
            <p>{t("Chưa có bộ lọc nào được áp dụng")}</p>

            <Divider orientation='horizontal' borderColor="#E5E7EB" />
        </>
    }

    const addFilterItem = () => {
        return <Button variant='default-default'
            onKeyDown={(event: any) => {
                if (event.key === "Enter") {
                    event.preventDefault();
                }
            }}
            onClick={(e: any) => {
                append({
                    ...defaultFilter,
                    conjunction: "and"
                });
            }}
        >
            {t('add_filter')}
        </Button>
    }

    const handleOpenModal = () => {
        setIsVisible(true)
        reset({ filter: filterDefault });
    }

    const handleCloseModal = () => {
        setTimeout(() => {
            setIsVisible(false)
        }, 10)
    }

    const headerModal = () => {
        return <div
            className="
                hrv-report-d-flex 
                hrv-report-items-center 
                hrv-report-justify-between"
        >
            <p
                className="
                hrv-report-mb-0
                hrv-report-text-color-black-6
                hrv-report-fs-16 
                hrv-report-fw-600
                "
            >{t("Quản lý bộ lọc")}</p>

            <span
                style={{
                    width: 18,
                }}
                className="
                hrv-report-d-flex 
                hrv-report-items-center 
                hrv-report-cursor-pointer
                hrv-report-justify-between
                "
                onClick={() => {
                    handleCloseModal()
                }}
            >
                {svgClose}
            </span>
        </div>
    }

    return (
        <>
            <ButtonFilter isDisplay onClick={handleOpenModal} />

            <Modal isOpen={isVisible} onClose={handleCloseModal} size="md" autoFocus={false} motionPreset={"slideInBottom"}>
                <ModalOverlay />

                <ModalContent
                    minWidth="fit-content"
                    height="fit-content"
                >
                    <ModalHeader>{headerModal()}</ModalHeader>

                    <form onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)}>
                        <ModalBody
                            w='600px'
                            maxH="520px"
                            overflowY="auto"
                            className="hrv-report-scrollbar_custom"
                        >
                            {!fields.filter(field => !disableFilterField.includes(field.dimension)).length ? emptyFilterState() : null}

                            {fields?.map((_: any, index) => {
                                const disabled = disableFilterField.includes(getValues(`filter.${index}.dimension`));

                                return !disableFilterField.includes(fields[index].dimension) ? <div key={_.id}>
                                    <FilterScore
                                        watch={watch}
                                        setValue={setValue}
                                        index={index}
                                        onHandleRemove={remove}
                                        control={control}
                                        dimensionList={resignDimension(getValues(`filter.${index}.dimension`))}
                                        getValue={getValues}
                                        defaultDimensionList={optionsDimension}
                                        dateStart={dateStart}
                                        dateEnd={dateEnd}
                                        reportId={reportId}
                                        setDisabledSubmit={setIsDisabledButton}
                                        disabled={disabled}
                                        reportNameSystem={reportNameSystem}
                                    />
                                </div> : null
                            })}

                            {addFilterItem()}
                        </ModalBody>

                        <ModalFooter w='600px'>
                            <Button variant='default-default' mr={4} onClick={handleCloseModal}>
                                {t("Huỷ")}
                            </Button>

                            <Button
                                variant='default-primary'
                                type="submit"
                                className={`buttonSubmit ${detectClass}`}
                                isDisabled={isDisabledButton}
                                onClick={handleCloseModal}
                                {...attributes}
                            >
                                {t("Áp dụng")}
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )
}

export default DesktopFilter;

const svgClose = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M15.8327 5.34167L14.6577 4.16667L9.99935 8.82501L5.34102 4.16667L4.16602 5.34167L8.82435 10L4.16602 14.6583L5.34102 15.8333L9.99935 11.175L14.6577 15.8333L15.8327 14.6583L11.1743 10L15.8327 5.34167Z" fill="#9CA3B0" />
    </svg>
);