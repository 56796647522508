import useSWR
    // { useSWRConfig }
    from "swr";
import { useFetchJson } from "@haravan/reactapp";

import md5 from "helpers/md5";
import { buildQueryString } from "helpers/helpers";
import dayjs from "components/Dayjs"

const REPORT_URI = import.meta.env.VITE_APP_API_SERVICES + "/";
const REPORT_CALL_URI_V2 = REPORT_URI + `reports/`;

// && Referecence: https://swr.vercel.app/docs/conditional-fetching.en-US#conditional
// && cacheTime will handle timing of cache if the same key have time over cacheTime, cache API will call and get new value + new key
export function useAutoCompleteV2({ id, body, preventFetch = false, cacheTime = 0 }) {
    const useHRVSWR: any = useSWR;
    // const { cache } = useSWRConfig();
    const fetchJson = useFetchJson();

    const key = md5(JSON.stringify(body));

    let url = "";
    let METHOD = "GET";
    let autocompletetype = "autocomplete";
    let payload = {};

    payload = buildQueryString({
        dimension: body.dimension,
        query: body.keyword || "",
        enddate: dayjs(body.enddate).endOf("day")
            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        startdate: dayjs(body.startdate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    });

    if (body.dimension === "DiscountId") {
        // const findValueRefer = filterSelected.find(y => y.dimension === "DiscountMethod");

        // & TH: là khuyến mãi thì filter thêm hình thức KM (CTKM | MKM)
        payload = buildQueryString({
            dimension: body.dimension,
            query: body.keyword || "",
            refer: "",
            enddate: dayjs(body.enddate).endOf("day")
                .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            startdate: dayjs(body.startdate)
                .startOf("day")
                .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        });
    }

    if (body.dimension === "Gateway") {
        autocompletetype = "autocompleteByGateway";
        payload = JSON.stringify({
            daterange: {
                enddate: body.enddate,
                startdate: body.startdate,
            }
        })
    }

    if (body.dimension === "ProductId" || body.dimension === "CollectionIds") {
        autocompletetype = "autocompleteById";

        payload = buildQueryString({
            dimension: body.dimension,
            isquerybyid: true,
            query: body.keyword || "",
            enddate: dayjs(body.enddate).endOf("day")
                .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            startdate: dayjs(body.startdate)
                .startOf("day")
                .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        });
    }

    // * Build Body
    switch (autocompletetype) {
        case 'autocomplete':
            url = `${REPORT_CALL_URI_V2}${id}/auto_complete${payload}&k=${key}`

            break;

        case 'autocompleteById':
            url = `${REPORT_CALL_URI_V2}${id}/auto_completebyid${payload}&k=${key}`;

            break;

        case 'autocompleteByGateway':
            url = `${REPORT_CALL_URI_V2}${id}/gatewayfilter?k=${key}`;
            METHOD = 'POST';

            break;

        default:
            break;
    }

    const fetcher = (url: string) =>
        fetchJson(url, {
            method: METHOD,
            headers: {
                "Content-Type": "application/json",
            },
            body: payload,
        });

    // const cacheData = cache.get(`${REPORT_CALL_URI_V2}${id}/auto_complete${payload}&${useCache ? `k=${key}` : ""}`)

    return useHRVSWR(preventFetch ? null : url, METHOD === "POST" ? fetcher : fetchJson, {
        revalidateOnFocus: false,
        refreshInterval: 0,
        revalidateIfStale: true,
        dedupingInterval: cacheTime
    });
}