import { Line } from "react-chartjs-2";
import * as ChartUtils from "helpers/chartutils";
import { useEffect, useState } from "react";
import { useStore } from "pages/ReportStandard/context/store";
import {
  ShowDateRange,
  formatDate,
} from "helpers/helpers";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import { useTrans } from "locales/hook";
import LegendChart from "./legend";
import { GetDateShowType } from "helpers/date";
import WrapperSpinner from "components/Spinner";
import TooltipLine from "./tooltip";
import useWindowDimensions from "hooks/useWindowDimension";

type TProps = {
  isLoading: boolean;
  configHeight: number;
  isAutoSetDateType: boolean; // true => sẽ sử dụng type auto cho date range | false => sẽ xài groupby mặc định VD: nhiều tháng => tháng | 1 ngày => type = giờ (auto)
  groupByCustom?: string;
  data: any
};

const parseQueryData = (response, chartShowType, diffCounts?) => {
  let result: Array<any> = [];
  let data = response?.data;

  data?.forEach((item) => {
    let newDate = new Date(item[0]);

    if (diffCounts !== undefined) {
      newDate = ChartUtils.dayAdd(newDate, diffCounts, chartShowType);
    }

    if (chartShowType !== "hour") newDate.setHours(0, 0, 0, 0);

    let formated = { date: newDate, total: 0, order: item[1] };
    result.push(formated);
  });

  return result;
};

const chartSetting = {
  tension: 0.4,
  pointRadius: 0,
  borderWidth: 2,
};

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
);

ChartUtils.RegisterTooltipPositioner();

/**
 * 
 * ! @Notice
 * Có 2 kiểu data 
 * 1 - Day: Kiểu ngày sẽ hỗ trợ loại chart có đối tượng là ngày và đối tượng là Ngày hiện tại và ngày so sánh (Bản chất là 2 đối tượng nhưng vân là Ngày nến chỉ gom thành Đối tượng ngày)
 * 2 - Khác: Kiểu khác sẽ vẽ theo đôi tượng ví dụ: Khách mới, Khách cũ, Khách vãng lai là 3 đối tượng
 * Workflow: 
 * (1): handleEffectData run: Lặp qua key của Data xem Data key là gì. Mỗi key sẽ là 1 đối tượng và mỗi đối tượng sẽ có 2 yếu tố hiện tại và so sánh
 *      sau đó sẽ fill data còn thiếu cho từng đối tượng
 * (2): handleDataChart run: 
 */
function LineChart(props: TProps) {
  const {
    isLoading,
    configHeight,
    isAutoSetDateType,
    groupByCustom,
    data
  } = props;

  const { state } = useStore();
  const t = useTrans();
  const {
    startdate,
    enddate,
    compareDateEnd,
    compareDateStart,
    optionMeasure,
    measure: measureList
  } = state;
  const { width } = useWindowDimensions();

  const checkCurrentDateAndPreviousDateIsTheSameYear = (array) => {
    return !array.every((v) => v === array[0]);
  };

  let isShowYear =
    compareDateStart || compareDateEnd
      ? checkCurrentDateAndPreviousDateIsTheSameYear([
        startdate.format("YYYY"),
        enddate.format("YYYY"),
        compareDateEnd.format("YYYY"),
        compareDateStart.format("YYYY"),
      ])
      : false;

  const measure = measureList.find(
    (m) => m.measureField === optionMeasure.measureField,
  );

  let dataFormat = "number";

  if (measure) {
    dataFormat =
      measure.measureName === "DiscountValueInPercent"
        ? "percentage"
        : measure.isMoney
          ? "currency"
          : "number";
  }

  const [dataChart, setDataChart] = useState<any>({
    labels: [],
    datasets: [],
  });
  const [options, setOptions] = useState<any>({});
  const [tooltip, setTooltip] = useState<any>(null);

  const { type: typeAuto } = GetDateShowType(startdate, enddate);

  const type = isAutoSetDateType ? typeAuto : groupByCustom

  useEffect(() => {
    handleEffectData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoading,
    data,
    compareDateStart,
    compareDateEnd,
    startdate,
    enddate,
  ]);

  const handleEffectData = async () => {
    if (!isLoading) {
      const xAxisElementLimit = 30;

      // * Hiện tại
      let labels: any = [];
      let dataCurrent: any = {};
      // * So sánh
      let labelsPrevious: any = [];
      let dataPrevious: any = {}

      // * Data Chart
      let datasets: any = [];

      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          // ! Add date when that date not exist in array
          let currentFormated: Array<any> = [];
          let previousFormated: Array<any> = [];
          let query = data[key]?.current || [];
          let queryPeriod = data[key]?.previous || [];
          let currentData = parseQueryData(query, type);

          let previousData =
            typeof queryPeriod.data !== "undefined" || Boolean(queryPeriod.data && queryPeriod.data.length)
              ? parseQueryData(queryPeriod, type)
              : [];

          ChartUtils.map2Chart(currentData, previousData);

          currentFormated = ChartUtils.formatData(
            currentData,
            startdate,
            enddate,
            type,
          );

          if (
            Boolean(previousData?.length > 0 &&
              typeof compareDateEnd !== "undefined" &&
              typeof currentFormated !== "undefined")
          ) {
            previousFormated = ChartUtils.formatData(
              previousData,
              compareDateStart,
              compareDateEnd,
              type,
            );
          }

          const {
            labels: labelsResult,
            labelsPrevious: labelsPreviousResult,
            datasets: datasetsResult
          }: any = await handleDataChart(currentFormated, previousFormated, key);

          datasets = datasets.concat(datasetsResult);
          dataCurrent[key] = currentFormated;
          dataPrevious[key] = previousFormated;
          labels = labelsResult;
          labelsPrevious = labelsPreviousResult;
        }
      };

      setDataChart({
        labels,
        datasets,
      });

      let optionsData: Record<string, any> = {};

      optionsData = ChartUtils.GetLineConfig(
        {
          labels,
          datasets,
        },
        (context) => {
          externalTooltipHandler(context, {
            labels,
            labelsPrevious,
            datasets,
            dataCurrent,
            dataPrevious
          });
        },
        dataFormat === "currency",
        xAxisElementLimit,
      ) as any;

      optionsData = {
        ...optionsData,
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              maxRotation: 0,
              minRotation: 0,
              color: "#111827",
              callback: function (val, index) {
                if (dataChart.labels.length <= xAxisElementLimit) {
                  return dataChart.labels[index];
                }

                return index % 3 === 0 || index === dataChart.labels.length - 1
                  ? dataChart.labels[index]
                  : "";
              },
            },
          },
          y: { ...optionsData.scales.y, beginAtZero: true },
        },
      };

      setOptions(optionsData);
    }
  }

  const handleDataChart = async (dataCurrent, dataPrevious, key) => {
    const labels: any = await handleLabels(dataCurrent);
    const labelsPrevious: any = await handleLabels(dataPrevious);
    const datasets: any = await handleDataSets(dataCurrent, dataPrevious, key);

    return {
      labels,
      labelsPrevious,
      datasets
    }
  };

  const handleLabels = (dataCurrent) => {
    let labels: any = [];
    let format = "DD/MM";
    let dataChartTime = ChartUtils.getDataByKey("date", dataCurrent);

    dataChartTime.forEach((time: any) => {
      if (type === "hour") {
        format = "HH:mm";
      }
      if (type === "day") {
        format = "DD/MM";
        if (startdate.format("YYYY") !== enddate.format("YYYY")) {
          format = "DD/MM/YYYY";
        }
      }
      if (type === "month") {
        format = "MM/YYYY";
      }

      if (type === "year") {
        format = "YYYY";
      }

      dataChart.labels.push(formatDate(time, format));
      labels.push(formatDate(time, format));
    });

    return labels;
  };

  const handleDataSets = (currentFormated, previousFormated, key) => {
    let datasets: any = [];

    let currentColor = "";
    let previousColor = "";

    const COLORS = {
      "Khách cũ": {
        current: "#3c83f6",
        previous: "",
      },
      "Khách mới": {
        current: "#f97415",
        previous: "",
      },
      "Khách vãng lai": {
        current: "#d1d5db",
        previous: "",
      }
    }

    if (key === "Day") {
      currentColor = "#3C83F6";
      previousColor = "#7ED4FC";
    } else {
      currentColor = COLORS[key].current
    }

    let currentData = {
      ...chartSetting,
      data: ChartUtils.getDataByKey(
        "order",
        currentFormated,
        dataFormat === "percentage",
      ),
      label: ShowDateRange(startdate, enddate),
      datasetKeyProvider: `${key}`,
      borderColor: currentColor,
      isPrevious: false
    };

    datasets.push(currentData);

    if (
      typeof compareDateStart !== "undefined" &&
      typeof compareDateEnd !== "undefined"
    ) {

      let previousData = {
        ...chartSetting,
        data: ChartUtils.getDataByKey(
          "order",
          previousFormated,
          dataFormat === "percentage",
        ),
        label: ShowDateRange(compareDateStart, compareDateEnd),
        datasetKeyProvider: `${key}`,
        borderColor: previousColor,
        borderDash: [3, 3],
        isPrevious: true
      };

      datasets.push(previousData);
    }

    return datasets;
  };

  const externalTooltipHandler = (context, dataInputChart) => {
    const { tooltip, chart } = context;
    const { datasets, dataCurrent, dataPrevious }: any = dataInputChart

    if (tooltip.opacity === 0) {
      setTooltip({
        opacity: 0,
      });
      return;
    };

    if (tooltip.body) {
      let indexData = tooltip.dataPoints[0].dataIndex;

      const body = datasets.map((b) => {
        let date = "";

        if (!b.isPrevious) {
          date = dataCurrent[b.datasetKeyProvider][indexData]?.date || "--";
        } else {
          date = dataPrevious[b.datasetKeyProvider][indexData]?.date || "--";
        }

        return {
          date,
          data: b.data[indexData] ?? "--",
          timerange: b.label,
          header: b.datasetKeyProvider,
          color: b.borderColor,
          isPrevious: b.isPrevious,
        }
      });

      // * Position cho tooltip
      const { x } = chart.tooltip._active[0].element;
      let tooltipEl: any = document.querySelector(
        ".tooltip-container",
      );

      if (tooltipEl) {
        let leftPosition = x;

        if (tooltip.xAlign === "right") {
          // Bên phải
          leftPosition = x - tooltipEl.clientWidth;
          if (leftPosition < 0) {
            leftPosition = 0;
          }
        } else {
          // Bên trái
          // Nếu vị trí và chiều rộng của tooltip + lại lớn hơn chiều rộng của màn hình thì tính lại vị trí của tooltip
          leftPosition = x;
          if (x + tooltipEl.clientWidth > document.body.clientWidth) {
            let diff = x + tooltipEl.clientWidth - document.body.clientWidth;
            leftPosition -= diff + 50;
          }
        }

        let top = tooltip.caretY - tooltipEl.clientHeight;
        tooltipEl.style.top = top + "px";

        const keyExistData = Object.keys(dataCurrent).filter(key => dataCurrent[key].length);

        setTooltip({
          opacity: 1,
          left: leftPosition + 'px',
          top: top + 'px',
          body,
          date: dataCurrent[keyExistData[0]][indexData]?.date || "--"
        });

      }
      else {
        setTooltip({
          opacity: 0,
          body,
        });
      }
    }
  };

  const plugins = [
    ChartUtils.hoverLine,
    (data.hasOwnProperty("Day") && ChartUtils.addGradientBgToDataset(0))
  ];

  return (
    <div className="hrv-report-detail-chart">
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: configHeight,
          }}
        >
          <WrapperSpinner />
        </div>
      ) : (
        <>
          {!!dataChart?.labels?.length && (
            <>
              <div
                style={{
                  width: "100%",
                  height: configHeight - (width < 854 ? dataChart?.datasets?.length * 32 : 32),
                }}
              >
                <Line
                  data={dataChart}
                  options={options}
                  plugins={plugins}
                />

                <TooltipLine
                  data={tooltip}
                  type={type}
                  isShowYear={isShowYear}
                  dataFormat={dataFormat}
                />
              </div>

              <div className="hrv-report-items-center hrv-report-justify-center hrv-report-w-100 hrv-report-mt-16 hrv-report-md-d-flex">
                {dataChart?.datasets?.map((line, idx) => {
                  return <div key={idx} className="hrv-report-px-8">
                    <div className="hrv-report-legend">
                      <LegendChart
                        title={line.datasetKeyProvider === "Day" ? line.label : t(line.datasetKeyProvider)}
                        color={line.borderColor}
                        status={line.isPrevious ? "dashed" : "line"}
                      />
                    </div>

                    <div className="hrv-report-legend-mobile">
                      <LegendChart
                        title={line.datasetKeyProvider === "Day" ? line.label : t(line.datasetKeyProvider)}
                        color={line.borderColor}
                        status={line.isPrevious ? "dashed" : "line"}
                      />
                    </div>
                  </div>
                })}
              </div>

            </>
          )}
        </>
      )}
    </div>
  );
}

export default LineChart;
