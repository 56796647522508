import useEffectOnce from "./useEffectOnce";

export const useStickyCalendar = ({
    id,
    threshold = 0
}) => {
    const handleScroll = () => {
        const reportHeader = document.getElementById(id);
        let top =
            (reportHeader?.getBoundingClientRect().top || 0) + window.scrollY;
        let reportHeaderHeight = reportHeader?.clientHeight || 0;
        const calendar: any = document.body.querySelectorAll(".daterangepicker");

        if (window.scrollY > 80) {
            if (calendar.length) {
                for (let i = 0; i < calendar.length; i++) {
                    calendar[i].style.top = top + reportHeaderHeight - 16 + threshold + "px";
                }
            }
            reportHeader?.classList.add("report_detail_header__scrolling");
        } else {
            if (calendar.length) {
                for (let i = 0; i < calendar.length; i++) {
                    calendar[i].style.top = top + reportHeaderHeight - 16 + threshold + "px";
                }
            }
            reportHeader?.classList.remove("report_detail_header__scrolling");
        }
    };

    useEffectOnce(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    });
}