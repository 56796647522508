import { GetCurrencyFormater, PointsToCommas } from "helpers/helpers";

const MoneyWithLocale = ({
  value,
  className = "",
  currencyCode = "VND",
  childrenSpan = true
}) => {
  let formater = GetCurrencyFormater();
  let options = formater.resolvedOptions();
  let formatedValue = formater.format(Math.round(value));
  if (options.locale === "vi-VN") {
    formatedValue = PointsToCommas(formatedValue);
  }

  return childrenSpan ? <span className={`${className} hrv-report-d-block`}>{formatedValue}</span> : formatedValue
}

export default MoneyWithLocale;