import { useTrans } from "locales/hook";
import { DATE_RANGES_DEFAULT } from "components/Dayjs/constants"

export default function useTranslate() {
  const t = useTrans();

  // * Support translate range text
  const customRanges = DATE_RANGES_DEFAULT(t);

  const locale = {
    applyLabel: t("applyLabel"),
    cancelLabel: t("cancelLabel"),
    format: "DD/MM/YYYY hh:mm A",
    separator: " - ",
    customRangeLabel: t("customRangeLabel"),
    weekLabel: t("weekLabel"),
    daysOfWeek: [
      t("Sun"),
      t("Mo"),
      t("Tu"),
      t("We"),
      t("Th"),
      t("Fr"),
      t("Sa"),
    ],
    monthNames: [
      t("January"),
      t("February"),
      t("March"),
      t("April"),
      t("May"),
      t("June"),
      t("July"),
      t("August"),
      t("September"),
      t("October"),
      t("November"),
      t("December"),
    ],
    ranges: customRanges,
  };

  const updateUI = (names) => {
    setTimeout(() => {
      for (const rangeName in names) {
        const rangeKey = document.querySelector(
          `[data-range-key="${rangeName}"]`
        );

        if (rangeKey || rangeKey !== null) {
          rangeKey.innerHTML = names[rangeName];
        }
      }
    }, 100);
  };

  const getLocaleDatePicker = () => {
    return locale;
  };

  return { getLocaleDatePicker, updateUI };
}
