import TableReportHeader from "components/Table/components/header";
import { mapOrder } from "helpers/helpers";
import { useTrans } from "locales/hook";
import { Fragment, useMemo } from "react";

function GroupHeader(props) {
  const {
    reportNameSystem,
    canReadMACost,
    dataHeaders,
    headersWidth,
    onHandleSortTable,
    sortable,
    sortValue
  } = props;

  const t = useTrans();

  const HEADER_IMPORT_AND_EXPORT_INVEN: any = useMemo(() => {
    return {
      left: [
        {
          rowSpan: 2,
          name: "ProductId",
          alignment: "left",
          type: "GroupProperty",
          isGroup: false,
        },
        {
          rowSpan: 2,
          name: "VariantId",
          alignment: "left",
          type: "GroupProperty",
          isGroup: false,
        },
        {
          rowSpan: 2,
          name: "SKU",
          alignment: "left",
          type: "GroupProperty",
          isGroup: false,
        },
        {
          rowSpan: 2,
          name: "Barcode",
          alignment: "left",
          type: "GroupProperty",
          isGroup: false,
        },
        {
          rowSpan: 2,
          name: "ProductCategoryId",
          alignment: "left",
          type: "GroupProperty",
          isGroup: false,
        },
        {
          rowSpan: 2,
          name: "Qty_OpeningStock",
          alignment: "right",
          type: "Measure",
          isGroup: false,
        },
        {
          rowSpan: 2,
          name: "CosAmountOpening",
          alignment: "right",
          check: canReadMACost,
          type: "Measure",
          isGroup: false,
          isDrag: false,
        },
        {
          colSpan: 5,
          name: "Import_quantity",
          alignment: "center",
          type: "Measure",
          isGroup: true,
        },
        {
          colSpan: 4,
          name: "Export_quantity",
          alignment: "center",
          type: "Measure",
          isGroup: true,
        },
        {
          rowSpan: 2,
          name: "Qty_ClosingStock",
          alignment: "right",
          type: "Measure",
          isGroup: false,
        },
        {
          rowSpan: 2,
          name: "CosAmountClosing",
          alignment: "right",
          check: canReadMACost,
          type: "Measure",
          isGroup: false,
        },
      ],
      right: [
        {
          name: "Qty_Init_In",
          alignment: "right",
        },
        {
          name: "Qty_Transfer_In",
          alignment: "right",
        },
        {
          name: "Qty_Adjustment_In",
          alignment: "right",
        },
        {
          name: "Qty_PurchaseOrder_In",
          alignment: "right",
        },
        {
          name: "Qty_SaleOrderRestock_In",
          alignment: "right",
        },
        {
          name: "Qty_Transfer_Out",
          alignment: "right",
        },
        {
          name: "Qty_Adjustment_Out",
          alignment: "right",
        },
        {
          name: "Qty_SaleOrderFulfill_Out",
          alignment: "right",
        },
        {
          name: "Qty_PurchaseOrderReturn_Out",
          alignment: "right",
          // style: { borderRight: "1px solid #e5e7eb" },
        },
      ],
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canReadMACost, dataHeaders]);

  const HEADER_ENTER_AND_RETURNS_BY_SUPPLIERS: any = useMemo(() => {
    return {
      left: [
        {
          rowSpan: 2,
          name: "ProductId",
          alignment: "left",
          type: "GroupProperty",
          isGroup: false,
        },
        {
          rowSpan: 2,
          name: "ProductTypeName",
          alignment: "left",
          type: "GroupProperty",
          isGroup: false,
        },
        {
          rowSpan: 2,
          name: "LocId",
          alignment: "left",
          type: "GroupProperty",
          isGroup: false,
        },
        {
          rowSpan: 2,
          name: "SupplierId",
          alignment: "left",
          type: "GroupProperty",
          isGroup: false,
        },
        {
          rowSpan: 2,
          name: "Phone",
          alignment: "left",
          type: "GroupProperty",
          isGroup: false,
        },
        {
          rowSpan: 2,
          name: "InvId",
          alignment: "left",
          type: "GroupProperty",
          isGroup: false,
        },
        {
          rowSpan: 2,
          name: "VariantId",
          alignment: "left",
          type: "GroupProperty",
          isGroup: false,
        },
        {
          rowSpan: 2,
          name: "SKU",
          alignment: "left",
          type: "GroupProperty",
          isGroup: false,
        },
        {
          rowSpan: 2,
          name: "Barcode",
          alignment: "left",
          type: "GroupProperty",
          isGroup: false,
        },
        {
          rowSpan: 2,
          name: "TransactionDate",
          alignment: "left",
          type: "GroupProperty",
          isGroup: false,
        },
        {
          rowSpan: 2,
          name: "TypeId",
          alignment: "left",
          type: "GroupProperty",
          isGroup: false,
        },
        {
          rowSpan: 2,
          name: "Email",
          alignment: "left",
          type: "GroupProperty",
          isGroup: false,
        },
        {
          colSpan: 6,
          name: "Nhập hàng",
          alignment: "center",
          type: "Measure",
          isGroup: true,
        },
        {
          colSpan: 6,
          name: "Trả hàng",
          alignment: "center",
          type: "Measure",
          isGroup: true,
        },
        {
          colSpan: 4,
          name: "Thực nhập",
          alignment: "center",
          type: "Measure",
          isGroup: true,
        },
      ],
      right: [
        {
          name: "ReceiveQty",
          alignment: "right",
        },
        {
          name: "ReceiveCostAmount",
          alignment: "right",
        },
        {
          name: "ReceiveDiscountAmount",
          alignment: "right",
        },
        {
          name: "ReceiveOtherExpensesNCCAmount",
          alignment: "right",
        },
        {
          name: "ReceiveOtherExpensesAmount",
          alignment: "right",
        },
        {
          name: "TotalReceiveAmount",
          alignment: "right",
        },
        {
          name: "ReturnQty",
          alignment: "right",
        },
        {
          name: "ReturnCostAmount",
          alignment: "right",
        },
        {
          name: "ReturnDiscountAmount",
          alignment: "right",
        },
        {
          name: "ReturnOtherExpensesNCCAmount",
          alignment: "right",
        },
        {
          name: "ReturnOtherExpensesAmount",
          alignment: "right",
        },
        {
          name: "TotalReturnAmount",
          alignment: "right",
        },
        {
          name: "ReturnQtyPercent",
          alignment: "right",
        },
        {
          name: "ActuallyReceivedQty",
          alignment: "right",
        },
        {
          name: "TotalAmount",
          alignment: "right",
        },
        {
          name: "CostPriceReference",
          alignment: "right",
          style: { borderRight: "1px solid #e5e7eb" },
        },
      ],
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataHeaders]);

  let groupHeader: any = reportNameSystem === "stock_invreceive_by_suppliers" ? HEADER_ENTER_AND_RETURNS_BY_SUPPLIERS : HEADER_IMPORT_AND_EXPORT_INVEN;

  // & Refresh header by groupproperty value
  let orderingGroupByHeader = groupHeader["left"].filter(y => y.type === "GroupProperty" && dataHeaders.findIndex(d => d.name === y.name) !== -1);
  const getMeasureHeader = groupHeader["left"].filter(y => y.type === "Measure");

  orderingGroupByHeader = mapOrder(orderingGroupByHeader, dataHeaders.map(y => y.name), 'name');

  groupHeader["left"] = orderingGroupByHeader.concat(getMeasureHeader);


  const handleSortTable = (value) => {
    onHandleSortTable && onHandleSortTable(value);
  };

  return (
    <>
      <tr>
        {groupHeader["left"].map((data, index) => {
          let thProps: any = {};
          let customClass = "";
          let canSort = sortable && !data.hasOwnProperty('colSpan');

          if (data.hasOwnProperty("colSpan")) {
            thProps.colSpan = data.colSpan;
          } else if (data.hasOwnProperty("rowSpan")) {
            thProps.rowSpan = data.rowSpan;
          }

          if (sortable) {
            customClass +=
              " hrv-report-table-column-sortable hrv-report-cursor-pointer ";
          }

          return (
            <Fragment key={index}>
              {!data.hasOwnProperty("check") ? (
                <th
                  className={`hrv-report-text-${data.alignment} ${index === 0 ? "hrv-report-first-column" : ""
                    } ${customClass}`}
                  key={index}
                  attr-name={data.name}
                  style={{
                    width: headersWidth ? headersWidth[data.name] : 'unset'
                  }}
                  {...thProps}
                  onClick={() => {
                    canSort && handleSortTable(data.name);
                  }}
                >
                   <TableReportHeader
                      reportNameSystem={reportNameSystem}
                      name={(data.name)}
                    />

                  {/* Có quyền sort và cột không phải là cột gộp */}
                  {canSort && (
                    <span className={`hrv-report-ml-4 hrv-report-cursor-pointer`}>
                      {sortValue.dimension !== data.name ? (
                        <>{svgDoubleArrow}</>
                      ) : (
                        <span
                          className={`${sortValue.direction === "desc"
                            ? "hrv-report-desc"
                            : "hrv-report-inc"
                            }`}
                        >
                          {svgArrowDown}
                        </span>
                      )}
                    </span>
                  )}
                </th>
              ) : data.check ? (
                <th
                  className={`hrv-report-text-${data.alignment} ${index === 0 ? "hrv-report-first-column" : ""
                    }`}
                  key={index}
                  {...thProps}
                  attr-name={data.name}
                  style={{
                    width: headersWidth ? headersWidth[data.name] : 'unset'
                  }}
                >
                  <TableReportHeader
                    reportNameSystem={reportNameSystem}
                    name={(data.name)}
                  />

                  {canSort && !data.hasOwnProperty('colSpan') && (
                    <span className={`hrv-report-ml-4 hrv-report-cursor-pointer`}>
                      {sortValue.dimension !== data.name ? (
                        <>{svgDoubleArrow}</>
                      ) : (
                        <span
                          className={`${sortValue.direction === "desc"
                            ? "hrv-report-desc"
                            : "hrv-report-inc"
                            }`}
                        >
                          {svgArrowDown}
                        </span>
                      )}
                    </span>
                  )}
                </th>
              ) : (
                <></>
              )}
            </Fragment>
          );
        })}
      </tr >

      <tr>
        {groupHeader["right"].map((data, index) => {
          let canSort = sortable && !data.hasOwnProperty('colSpan');

          return (
            <th
              className={`hrv-report-text-${data.alignment}`}
              key={`${index}_abc`}
              attr-name={data.name}
              style={{
                ...data.style,
                minWidth: headersWidth ? headersWidth[data.name] : 'unset'
              }}
              onClick={() => {
                canSort && handleSortTable(data.name);
              }}
            >
              <TableReportHeader
                reportNameSystem={reportNameSystem}
                name={data.name}
              />

              {sortable && (
                <span className={`hrv-report-ml-4 hrv-report-cursor-pointer`}>
                  {sortValue.dimension !== data.name ? (
                    <>{svgDoubleArrow}</>
                  ) : (
                    <span
                      className={`${sortValue.direction === "desc"
                        ? "hrv-report-desc"
                        : "hrv-report-inc"
                        }`}
                    >
                      {svgArrowDown}
                    </span>
                  )}
                </span>
              )}
            </th>
          );
        })}
      </tr>
    </>
  );
}

export default GroupHeader;


const svgDoubleArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_1583_19978)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1138 8L10.0004 5.88667L7.8871 8L6.94043 7.06L10.0004 4L13.0538 7.06L12.1138 8ZM7.8871 12L10.0004 14.1133L12.1138 12L13.0604 12.94L10.0004 16L6.9471 12.94L7.8871 12Z"
        fill="#9CA3B0"
      />
    </g>
    <defs>
      <clipPath id="clip0_1583_19978">
        <rect width="20" height="20" rx="2" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const svgArrowDown = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M9.9997 12.5001L6.46387 8.96422L7.64303 7.78589L9.9997 10.1434L12.3564 7.78589L13.5355 8.96422L9.9997 12.5001Z"
      fill="#2463EB"
    />
  </svg>
);
