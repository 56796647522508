import {
    EffectCallback,
    useEffect,
} from "react";
import { useComparision } from "components/Metrics/hooks/useComparison";
import { GetDateShowType } from "helpers/date";
import { isEmpty } from "helpers/helpers";
import { xmlRenderCheckbox } from "helpers/helpers";
import { LIMIT_GROUPED_BY_DISCOUNT } from "components/Metrics";
import { TIMESTAMP_OBJECT } from "components/Metrics/constants";
import { category_chart } from "pages/ReportDashboardV2/context/store/constants";

interface IInput {
    id: string | number | undefined
    name: string
    body: any
    preventFetch: boolean
    keepUsingTableData: boolean
    displayChart: string
}

// && Referecence: https://swr.vercel.app/docs/conditional-fetching.en-US#conditional
/**
 * @Function Hook to get Data for Chart
 * @Props keepUsingTableData: 
 *        id: report id
 *        name: report system name
 *        body: Params to fetch data
 *        preventFetch: true start build dataFetchChart | false do nothing!
 *        keepUsingTableData: true use data from table not use data from API and opposite
 * @Flow 
 * step 1: check keepUsingTableData if true return all logic of hook (don't do anything) 
 * step 2: check preventFetch if false => build payload for chart fit with reportsystemname
 *         after build payload success trigger: true => get Data for chart
 * step 3: dataFetchChart apply for useComparision hook and fetch data
 * step 4: if body change trigger set to false and preload to step 2 again
 * @returns current and previous data
 */


const LIMIT_RECORDS = 1000;

export function useMetrics(input: IInput) {
    const {
        id,
        name,
        body,
        keepUsingTableData,
        preventFetch = true,
        displayChart
    } = input;

    const useEffectCheckKeepUsingTableData = (callback: EffectCallback) => {
        return useEffect(callback, [keepUsingTableData])
    };

    useEffectCheckKeepUsingTableData(() => {
        if (keepUsingTableData) {
            return;
        }
    });

    const applyDiscountFilter = (payload) => {
        let newPayload = JSON.parse(JSON.stringify(payload));
        const { discount } = body;

        const fetchdata = {
            ...newPayload,
        };

        if (name === "sales_promotion") {
            if (
                fetchdata.hasOwnProperty("filters") &&
                Array.isArray(fetchdata.filters)
            ) {
                let locIndex = fetchdata.filters.findIndex(
                    (m) => m.dimension === "DiscountId",
                );
                if (locIndex === -1) {
                    if (discount.id !== undefined) {
                        fetchdata.filters.push({
                            conjunction: "and",
                            dimension: "DiscountId",
                            query: [
                                {
                                    conjunction: "or",
                                    symbol: "is",
                                    value: `${discount.id}`,
                                },
                            ],
                        });
                    }
                } else {
                    if (discount.id !== undefined) {
                        fetchdata.filters[locIndex] = {
                            conjunction: "and",
                            dimension: "DiscountId",
                            query: [
                                {
                                    conjunction: "or",
                                    symbol: "is",
                                    value: `${discount.id}`,
                                },
                            ],
                        };
                    } else {
                        fetchdata.filters.splice(locIndex, 1);
                    }
                }
            }
        }

        return fetchdata;
    };

    const {
        // * options mặc định
        filters,
        groupby,
        metrics,
        order_by,
        pagination,
        compareDateStart,
        compareDateEnd,
        startdate,
        enddate,
        // * options tuỳ chỉnh
        groupBySelected,
        optionMeasure
    } = body;

    let originalPayloadTable = {
        filters,
        groupby,
        metrics,
        order_by,
        pagination,
        compareDateStart,
        compareDateEnd,
        startdate,
        enddate,
    };

    if (!preventFetch) {
        const { type } = GetDateShowType(startdate, enddate);

        let groupby_custom: string[] = groupby;
        let pagination_custom: { offset: number; limit: number } = pagination;
        let metric_custom: Array<{ measureField: string; measureName: string }> = metrics;
        let order_by_custom: { dimension: string, direction: string } = order_by;

        /**
         * Nếu sử dụng groupby để vẽ chart
         * dataformat === timestamp thì sẽ group theo thời gian
         * dataformat !== timstamp thì sẽ vẽ theo groupby 
         */
        const groupbyHandler = (groupby) => {
            return [groupby.toLowerCase()];

            // if (TIMESTAMP_OBJECT.includes(groupby)) {
            //     return [groupby.toLowerCase()]
            // } else {
            //     return [groupby.toLowerCase()]
            // }
        }

        const ordebyHandler = (groupby, metric) => {
            if (TIMESTAMP_OBJECT.includes(groupby)) {
                return {
                    dimension: groupby,
                    direction: "asc",
                }
            } else {
                return {
                    dimension: metric.measureField,
                    direction: "desc",
                }
            }
        }

        const limitHandler = (groupby) => {
            return TIMESTAMP_OBJECT.includes(groupby) ? LIMIT_RECORDS : LIMIT_GROUPED_BY_DISCOUNT
        }

        switch (name) {
            case 'sales_overview_promotion': {
                const groupPropertyName = groupBySelected[0].groupPropertyName;
                groupby_custom = groupbyHandler(groupPropertyName);


                metric_custom = [{
                    measureField: "TotalDiscount",
                    measureName: "TotalDiscount",
                }];

                pagination_custom = {
                    offset: 0,
                    limit: limitHandler(groupPropertyName),
                };

                let newOriginalPayloadTable = JSON.parse(
                    JSON.stringify({
                        ...applyDiscountFilter(originalPayloadTable),
                    }),
                );

                originalPayloadTable = {
                    ...newOriginalPayloadTable
                };

                break;
            }

            case "sales_orders_salechannel_branch": {
                groupby_custom = ["sourcename"];
                metric_custom = [
                    {
                        measureField: "NetSaleAmount",
                        measureName: "NetSaleAmount",
                    },
                ];
                pagination_custom = { offset: 0, limit: 100 };
                order_by_custom = {
                    dimension: "NetSaleAmount",
                    direction: "desc",
                }

                break;
            }

            case "customers_new_and_return": {
                if (isEmpty(optionMeasure)) break;

                groupby_custom = ["customertype"];
                order_by_custom = {
                    dimension: "customertype",
                    direction: "asc",
                }

                if (displayChart === category_chart.LINE) {
                    groupby_custom.unshift('day');
                    order_by_custom = {
                        dimension: "day",
                        direction: "asc",
                    }
                }

                metric_custom = [
                    {
                        measureField: optionMeasure.measureField,
                        measureName: optionMeasure.measureName
                    },
                ];
                pagination_custom = { offset: 0, limit: 1000, };

                break;
            }

            case "customers_by_location": {
                groupby_custom = ["customerprovinceid"];
                metric_custom = [
                    {
                        measureField: "CustomerCountSpecial",
                        measureName: "CustomerCountSpecial"
                    },
                ];
                pagination_custom = { offset: 0, limit: 100 };
                order_by_custom = {
                    dimension: "customerprovinceid",
                    direction: "asc",
                }

                break;
            }

            case "order_shipments_detail_carriers_internal_speed":
            case "order_shipments_overview_carriers_internal_speed":
                const getMetricForChart = metrics.filter((value) => {
                    return xmlRenderCheckbox[name].includes(value.measureName);
                });

                metric_custom = getMetricForChart;
                groupby_custom = [groupby[0]];
                pagination_custom = { offset: 0, limit: 15 };
                order_by_custom = {
                    dimension: groupby[0],
                    direction: "desc",
                };

                break;

            case "customers_by_rfm": {
                groupby_custom = ["customersegment"];
                metric_custom = [
                    {
                        "measureField": "CustomerCountSpecial",
                        "measureName": "CustomerCountSpecial"
                    },
                    {
                        "measureField": "DayDiffPurchase",
                        "measureName": "DayDiffPurchase"
                    },
                    {
                        "measureField": "OrderCountSpecial",
                        "measureName": "OrderCountSpecial"
                    },
                    {
                        "measureField": "NetSaleAmount",
                        "measureName": "NetSaleAmount"
                    },
                    {
                        measureField: "AverageOrderValueAmount",
                        measureName: "AverageOrderValueAmount"
                    }
                ];
                pagination_custom = { offset: 0, limit: 100 };
                order_by_custom = {
                    dimension: "customersegment",
                    direction: "asc",
                }

                break;
            };

            case "sales_orders_staff": {
                if (isEmpty(optionMeasure)) break;
                const groupPropertyName = groupBySelected[0].groupPropertyName;
                groupby_custom = groupbyHandler(groupPropertyName);
                metric_custom = [
                    {
                        measureField: optionMeasure.measureField,
                        measureName: optionMeasure.measureName
                    },
                ];
                order_by_custom = ordebyHandler(groupPropertyName, optionMeasure);
                pagination_custom = {
                    offset: 0,
                    limit: limitHandler(groupPropertyName),
                };
                break;
            }

            default:
                break;
        };

        originalPayloadTable = {
            ...originalPayloadTable,
            groupby: groupby_custom,
            metrics: metric_custom,
            pagination: pagination_custom,
            order_by: order_by_custom
        };
    };

    const {
        current,
        previous,
        isLoadingCurrent,
        isLoadingPrevious,
        isValidatingCurrent,
        isValidatingPrevious,
        mutate
    } = useComparision({
        reportScreenId: id,
        reportNameSystem: name,
        dataToFetchChart: originalPayloadTable,
        optionCompare: body.optionCompare,
        discount: body.discount,
        preventFetch
    });

    return {
        isLoading: isLoadingCurrent || isLoadingPrevious,
        isValidating: isValidatingCurrent || isValidatingPrevious,
        data: {
            current,
            previous
        },
        mutate
    }
}